<div class="row mt-5 flex-row-reverse">

  <div class="col-lg-5 d-flex justify-content-center flex-column mb-5">
    <div class="row section-4-header-section">
      <h3 class="section-4-tobc-header"> All our benefits have been designed having in mind your well-being</h3>
    </div>
    <div class="row section-4-paragraph-section">
      <p class="section-4-tobc-paragraph"> Our products and services are as flexible as our organization,
        have a people-first approach, and always well-being at heart.
        Moreover, our services were designed to bring a return on investment,
        whether you are a company or an employee.
      </p>
    </div>

    <div class="row">
      <button class="btn contact-tobc-btn my-2 my-sm-0" type="submit" onclick="location.href = 'https://traveltodesk.com/contact';">Contact TOBC</button>
    </div>
  </div>

  <div class="col-lg-1"></div>

  <div class="col-lg-6 mb-5">
    <img src="assets/pictures/section_4_graphic.svg" alt="Section 3 Graphic" class="img-fluid"/>
  </div>
</div>
