import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-section-eight',
  templateUrl: './section-eight.component.html',
  styleUrls: ['./section-eight.component.scss']
})
export class SectionEightComponent implements OnInit {

  options = {
    updateOnMove: true,
    type: 'loop',
    drag: true,
    width: '100vw',
    arrows: false,
    cloneStatus: false,
    pagination: false,
    gap: '3vw',
    fixedWidth: '30rem',
    trimSpace: false,
    autoScroll: {
      speed: 2
    },
    autoplay: 'play',
    interval: 1000,
    pauseOnFocus: true,
  };

  images = [
    {src: 'assets/pictures/section-8-one.png'},
    {src: 'assets/pictures/section-8-two.png'},
    {src: 'assets/pictures/section-8-three.png'},
    {src: 'assets/pictures/section-8-four.png'},
    {src: 'assets/pictures/section-8-one.png'},
    {src: 'assets/pictures/section-8-two.png'},
    {src: 'assets/pictures/section-8-three.png'},
    {src: 'assets/pictures/section-8-four.png'},
  ];

  optionsCompanies = {
    updateOnMove: true,
    type: 'loop',
    drag: true,
    width: '100vw',
    arrows: false,
    cloneStatus: false,
    pagination: false,
    gap: '3vw',
    fixedWidth: '25rem',
    trimSpace: false,
    autoScroll: {
      speed: 2
    },
    autoplay: 'play',
    interval: 1000,
    pauseOnFocus: true,
  };

  imagesCompanies = [
    {src: 'assets/pictures/1667220897949-3.png'},
    {src: 'assets/pictures/1671993117462-Campion_Broker.jpeg'},
    {src: 'assets/pictures/1671993147341-Rdi.png'},
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
