<div class="container" style="z-index: 10">
  <div class="row justify-content-center text-center">
    <div>
      <h1 class="header-section-8">May we know why you are<br/> still scrolling?</h1>
    </div>
  </div>


  <div class="row justify-content-center text-center">
    <p class="paragraph-section-8">Join our platform now and enter the world of unlimited benefits.</p>
  </div>

</div>

<div style="height: 450px; position: relative">
  <div class="ellipse"></div>
  <div class="ellipse-bottom "></div>

  <div class="album">
    <splide [options]="options">
      <splide-slide *ngFor="let image of images">
        <img src="{{image.src}}" alt="Carousel Image"/>
      </splide-slide>
    </splide>
  </div>
</div>

<!--<div class="container mt-5" style="z-index: 10">-->
<!--  <div class="row justify-content-center text-center">-->
<!--    <div>-->
<!--      <h1 class="header-section-8">Companies that Trust ToBC</h1>-->
<!--    </div>-->
<!--  </div>-->


<!--  <div class="row justify-content-center text-center">-->
<!--    <p class="paragraph-section-8">With us from the beginning <br/> And will always be by our side</p>-->
<!--  </div>-->

<!--</div>-->

<!--<div class="mt-5" style="height: 500px; position: relative">-->
<!--  <div class="album">-->
<!--    <splide [options]="optionsCompanies">-->
<!--      <splide-slide *ngFor="let image of imagesCompanies">-->
<!--        <img src="{{image.src}}" alt="Carousel Image Companies" width="220" height="210"/>-->
<!--      </splide-slide>-->
<!--    </splide>-->
<!--  </div>-->
<!--</div>-->

