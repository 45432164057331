<div class="row justify-content-center text-center mt-5 pt-5">
  <div class="mt-header">
    <p class="header-paragraph-5">The Only Benefits Company</p>
  </div>
</div>

<div class="row justify-content-center text-center">
  <div>
    <p class="paragraph-section-5"> The new world of unlimited benefits for employees, digital nomads, students and athletes.</p>
  </div>
</div>
<div class="section-5-card-container mt-2">
  <div class="row justify-content-center text-center">
    <div class="col-lg-3">
      <div class="card section-5-card mb-3">
        <div class="card-body d-flex justify-content-center flex-column p-3">
          <div class="text-padding">
            <p class="card-text section-5-card-paragraph ">Equal in price or less than a subscription to a
              streaming service. Why so convenient? Because we care about people.</p>
            <p class="card-text section-5-card-paragraph ">Available for one year with renewal possibility.</p>
          </div>
        </div>
      </div>
      <div class="card section-5-card mb-5">
        <div class="card-body d-flex justify-content-center flex-column p-3">
          <div class="text-padding">
            <p class="card-text section-5-card-paragraph ">Less than 10% of the lowest* employee annual benefits budget. Why so convenient?<br/>Because we are a modular company, allowing you to choose the best product or service suitable for your company. We are an Add-on and yes, we are a real benefit.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 d-flex justify-content-center mb-5">
      <img src="assets/pictures/section_5_graphic.svg" class="img-fluid" alt="Section 5 Graphic"/>
    </div>
    <div class="col-lg-3">
      <div class="card section-5-card mb-3">
        <div class="card-body d-flex justify-content-center flex-column p-3">
          <div class="text-padding">
            <p class="card-text section-5-card-paragraph ">Network available in various countries. We care about
              our users and we want employees, partners and digital nomads to have a real valuable
              experience with an immediate return of investment</p>
          </div>
        </div>
      </div>
      <div class="card section-5-card mb-5">
        <div class="card-body d-flex justify-content-center flex-column p-3">
          <div class="text-padding">
            <p class="card-text section-5-card-paragraph ">Easy to acquire. Easy to implement. Easy to use. Easy to integrate our API.</p>
            <p class="card-text section-5-card-paragraph ">We are here for you and dedicated to your well-being!</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
