import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-seven',
  templateUrl: './section-seven.component.html',
  styleUrls: ['./section-seven.component.scss']
})
export class SectionSevenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
