<div class="row mt-5 justify-content-between">
  <div class="col-lg-6 justify-content-center flex-column mt-5">
    <div class="row section-6-header-section">
      <img class="img-fluid section-6-ttd-logo" src="assets/logos/logo_ttd_section_6.svg" alt="Graphic Image Travel To Desk Section 6"/>
    </div>

    <div class="row section-6-paragraph-section">
      <p class="section-6-tobc-paragraph">First product Developed by TOBC, which supports and improves the new way of working and employee retention rate, with an immediate return of investment.</p>
      <p class="section-6-tobc-paragraph">Developed with love from Romania, Travel To Desk believes that there is a better way of spending quality time with your colleagues, peers, team and family.</p>
      <p class="section-6-tobc-paragraph">With its ability to deliver immediate value, Travel To Desk is a flexible product that can be used by any demographic cohort of users.</p>

    </div>

    <div class="row section-6-btn">
      <button class="btn contact-tobc-btn my-2 my-sm-0" type="submit" onclick="location.href = 'https://traveltodesk.com/contact';">Contact TOBC</button>
    </div>

  </div>
  <div class="col-lg-6 section-6-image">
    <img class="img-fluid" src="assets/pictures/graphic_section_6.svg" alt="Graphic Image Travel To Desk Section 6"/>
  </div>
</div>
