import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent implements OnInit {
  static PRIMARY = 'primary';
  static SECONDARY = 'secondary';

  mouseOver = false;
  mouseOverTwo = false;
  mouseOverThree = false;

  constructor() { }

  ngOnInit(): void {
  }

  get PRIMARY(): string {
    return CallToActionComponent.PRIMARY;
  }

  get SECONDARY(): string {
    return CallToActionComponent.SECONDARY;
  }
}


