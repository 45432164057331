import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  type = '';
  acceptedTerms = false;
  university = '';
  firstName = '';
  lastName = '';
  email = '';

  constructor() { }

  ngOnInit(): void {
  }

  btnDisabled() {

    return this.type !== '' && this.firstName !== '' && this.lastName !== ''
      && this.email !== '' && this.university !== '' && this.acceptedTerms !== true;
  }

}
