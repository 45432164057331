<!-- Start Footer -->
<footer class="section-padding footer bg-light mt-5">
  <div class="container">
    <div class="row footer-data">
      <div class="col-lg-3 col-md-4 my-4">
        <div class="logo">
          <a routerLink="/">
            <img src="assets/logos/logo_tobc_footer.svg" class="img-fluid image-fit" alt="img">
          </a>
        </div>
        <div class="mb-4">
          <img src="assets/logos/tobc_footer_facebook.svg" alt="Facebook" class="mr-2"/>
          <img src="assets/logos/tobc_footer_instagram.svg" alt="Instagram"/>
        </div>
        <p class="footer-text">©2022 tobc.ro all rights reserved. </p>
      </div>

      <div class="col-lg-3 col-md-4 my-4">
        <div class="footer-box mb-md-40">
          <h4 class="fw-600">Product & Resources</h4>
          <ul class="list">
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/1" class="anchor-text-color ">2023 Digital Working Certificate</a>
            </li>
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/2" class="anchor-text-color ">New Ways Of Working</a>
            </li>
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/3" class="anchor-text-color ">Story, Roadmap and Developing Notes</a>
            </li>
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/4" class="anchor-text-color ">Are you a Company?</a>
            </li>
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/5" class="anchor-text-color ">Are you an Employee?</a>
            </li>
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/6" class="anchor-text-color ">Are you a Digital nomad? </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 my-4">
        <div class="footer-box mb-md-40">
          <h4 class="fw-600">Service Providers</h4>
          <ul class="list">
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/7" class="anchor-text-color ">Are you a Restaurant or Cafe?</a>
            </li>
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/8" class="anchor-text-color ">Are you a Travel or Remote Working Friendly Location? </a>
            </li>
            <li class="list-text">
              <a href="https://traveltodesk.com/blog-details/9" class="anchor-text-color ">Are you a Service Provider? </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 my-4">
        <div class="footer-box mb-md-40">
          <h4 class="fw-600">Benefits</h4>
          <ul class="list">
            <li class="list-text">
              <a href="#section3" class="anchor-text-color">Customer Satisfaction</a>
            </li>

            <li class="list-text">
              <a href="#section5" class="anchor-text-color">Company Advantages</a>
            </li>

            <li class="list-text">
              <a href="#section6" class="anchor-text-color">Travel To Desk</a>
            </li>

          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 my-4">
        <div class="footer-box mb-md-40">
          <h4 class="fw-600">Contact Us</h4>
          <p class="anchor-text-color">Email: office@tobc.ro</p>
          <p class="anchor-text-color">
            Phone: +40744400796
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="bg-white footer container">
  <div class="row flex-row justify-content-center mb-2">
    <div class="col-3 d-flex margin-top-european-flag justify-content-center">
      <img class="img-resp" src="assets/pictures/eu_image.png" alt="European Union Flag"/>

    </div>

    <div class="col-3 d-flex justify-content-center">
      <img class="img-resp" src="assets/pictures/romanian_gov_img.png" alt="Romanian Government"/>

    </div>

    <div class="col-3 d-flex justify-content-center">
      <img class="img-resp" src="assets/pictures/instrumente_structurale_img.png" alt="Instrumente Structurale"/>

    </div>
  </div>
</div>
<!-- End Footer -->
<!-- Start Copyright -->

