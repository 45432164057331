<div class="header-section bg-light">
  <div class="header-container">
    <app-header></app-header>
    <div class="row mt-5 justify-content-between hero-section">
      <div class="col-lg-6 hero-text-section">
        <div class="row hero-header-section">
          <p class="h1-tobc-header"> The Only Benefits Company</p>
        </div>
        <div class="row hero-paragraph-section">
          <p class="paragraph-hero"> The new world of unlimited benefits for employees, digital nomads,
            students and athletes.</p>
        </div>
        <div class="row">
          <button class="btn contact-tobc-btn my-2 my-sm-0" type="submit"
                  onclick="location.href = 'https://traveltodesk.com/contact';">Contact TOBC
          </button>
        </div>
      </div>

      <div class="col-lg-6 hero-picture-section container">
        <img class="center" src="assets/pictures/TOBC_logo.png" alt="TOBC_logo"/>
      </div>

    </div>
  </div>
</div>

<div class="cta-container mt-5">
  <app-call-to-action></app-call-to-action>
</div>

<div class="leaders-container mt-5">
  <div class="row justify-content-center text-center mt-5">
    <div>
      <p class="header-paragraph-5">Future Leaders of Tomorrow</p>
    </div>
  </div>
  <div class="row justify-content-center text-center mb-5">
    <div>
      <p class="paragraph-section-5"> Unlocking a world of benefits for corporate teams and digital nomads while empowering the future leaders of tomorrow <br/> (Athletes, Paralympians, Students and University Professors) —for free</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 justify-content-center">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div class="d-flex justify-content-center align-items-center flex-column card-above-leader">
          Prof. univ. dr. Costin Lianu
        </div>
      </div>
      <div class="card text-center cta-dimensions leader-card" style="background-image: url('/assets/pictures/professor.png')">
        <div class="card-body d-flex flex-column-reverse cta-card-body align-items-center" >
          <span class="p-2 badge badge-custom badge-secondary">Coming Soon</span>
          <h5 class="card-title cta-title"></h5>
          <p class="card-text cta-text"></p>
          <a class="btn p-3 cta-button-style">
            PROFESSORS
          </a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 justify-content-center">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div class="d-flex justify-content-center align-items-center flex-column card-above-leader">
          Students
        </div>
      </div>
      <div class="card text-center cta-dimensions leader-card" style="background-image: url('/assets/pictures/students.png')">
        <div class="card-body d-flex flex-column-reverse cta-card-body align-items-center" >
          <span class="p-2 badge badge-custom badge-secondary">Coming Soon</span>
          <h5 class="card-title cta-title"></h5>
          <p class="card-text cta-text"></p>
          <a class="btn p-3 cta-button-style">
            STUDENTS
          </a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 justify-content-center">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div class="d-flex justify-content-center align-items-center flex-column card-above-leader">
          Govoreanu Dana
        </div>
      </div>
      <div class="card text-center cta-dimensions leader-card" style="background-image: url('/assets/pictures/athlete.png')">
        <div class="card-body d-flex flex-column-reverse cta-card-body align-items-center" >
          <h5 class="card-title cta-title"></h5>
          <p class="card-text cta-text"></p>
          <a class="btn p-3 cta-button-style" href="https://traveltodesk.com/blog-details/11">
            ATHLETES
          </a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 justify-content-center">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div class="d-flex justify-content-center align-items-center flex-column card-above-leader">
          Mihăiță Papară
        </div>
      </div>
      <div class="card text-center cta-dimensions leader-card" style="background-image: url('/assets/pictures/paralympic.png')">
        <div class="card-body d-flex flex-column-reverse cta-card-body align-items-center" >
          <h5 class="card-title cta-title"></h5>
          <p class="card-text cta-text"></p>
          <a class="btn p-3 cta-button-style" style="width: 210px" href="https://traveltodesk.com/blog-details/12">
            PARALYMPIC ATHLETES
          </a>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="section-3-container mt-5">
  <app-section-three id="section3"></app-section-three>
  <app-section-four></app-section-four>
</div>

<div class="section-5-container mt-5 bg-light">
  <app-section-five id="section5"></app-section-five>
</div>

<div class="section-6-container mt-5">
  <app-section-six id="section6"></app-section-six>
</div>

<div class="mt-5 bg-light">
  <app-section-seven></app-section-seven>
</div>

<div>
  <app-section-eight></app-section-eight>
</div>

<div class="auth-wrapper d-flex no-block justify-content-center align-items-center mt-5" id="tobc-form">
  <div class="auth-box m-3 m-md-0 card">
    <div style="border: black">
      <div class="text-center">
        <h3 class="font-medium mb-4 card-title">TOBC Registration Form</h3>
        <p>Enter a world of flexible benefits for Free if you are a Leader of Tomorrow!</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form #form class="form-horizontal mt-3" id="TOBC Registration Form"
              action="https://www.formbackend.com/f/67222c60912e2d0c" method="post">
          <!-- Step 1: First Name and Second Name -->
          <div class="form-group">
            <label for="first-name">First Name:</label>
            <input class="form-control" type="text" id="first-name" name="firstName" [(ngModel)]="firstName">
          </div>
          <div class="form-group">
            <label for="last-name">Last Name:</label>
            <input class="form-control" type="text" id="last-name" name="lastName" [(ngModel)]="lastName">
          </div>

          <!-- Step 2: Email -->
          <div class="form-group">
            <label for="email">Email:</label>
            <input class="form-control" type="email" id="email" name="email" [(ngModel)]="email">
          </div>

          <!-- Step 3: Category -->
          <div class="form-group">
            <label for="type">Type:</label>
            <select class="form-control" id="type" name="type" [(ngModel)]="type">
              <option value="">Select Category</option>
              <option value="student">Student</option>
              <option value="athlete">Athlete</option>
              <option value="university-professor">University Professor</option>
            </select>
          </div>

          <!-- Step 4: Universities Dropdown -->
          <div class="form-group" id="university-field"
               *ngIf="type !== '' && type !== 'athlete'">
            <label for="university">University:</label>
            <select class="form-control" id="university" name="university" [(ngModel)]="university">
              <option value="">Select University</option>
              <option value="Petroleum-Gas University of Ploiesti">Petroleum-Gas University of Ploiesti (Universitatea
                Petrol-Gaze din Ploiești)
              </option>
              <option value="University of Bucharest">University of Bucharest (Universitatea din București)</option>
              <option value="Politehnica University of Bucharest">Politehnica University of Bucharest (Universitatea
                Politehnica din București)
              </option>
              <option value="Carol Davila University of Medicine and Pharmacy">Carol Davila University of Medicine and
                Pharmacy (Universitatea de Medicină și Farmacie "Carol Davila" din București)
              </option>
              <option value="National University of Political Studies and Public Administration">National University of
                Political Studies and Public Administration (Universitatea Națională de Studii Politice și
                Administrative)
              </option>
              <option value="Academy of Economic Studies in Bucharest">Academy of Economic Studies in Bucharest
                (Academia de
                Studii Economice din București)
              </option>
              <option value="National University of Arts in Bucharest">National University of Arts in Bucharest
                (Universitatea
                Națională de Arte din București)
              </option>
              <option value="National University of Music Bucharest">National University of Music Bucharest
                (Universitatea
                Națională de Muzică București)
              </option>
              <option value="Carol I National Defence University">Carol I National Defence University (Universitatea
                Națională
                de Apărare "Carol I")
              </option>
              <option value="Technical Military Academy Ferdinand I">Technical Military Academy Ferdinand I (Academia
                Tehnică
                Militară "Ferdinand I")
              </option>
              <option value="Spiru Haret University">Spiru Haret University (Universitatea Spiru Haret)</option>
              <option value="Titu Maiorescu University">Titu Maiorescu University (Universitatea Titu Maiorescu)
              </option>
              <option value="Hyperion University">Hyperion University (Universitatea Hyperion)</option>
              <option value="Ecological University of Bucharest">Ecological University of Bucharest (Universitatea
                Ecologică
                din București)
              </option>
              <option value="Romano-American University">Romano-American University (Universitatea Româno-Americană)
              </option>

              <!-- Add more universities as needed -->
            </select>
          </div>

          <!-- Step 5: Sport Club Title (Conditional) -->
          <div class="form-group" id="sport-club-field" *ngIf="type !== '' && type === 'athlete'">
            <label for="sport-club">Representing Sport Club:</label>
            <input class="form-control" type="text" id="sport-club" name="sport-club">
          </div>

          <!-- Step 6: Terms and Conditions Checkbox -->
          <div class="form-group">
            <label>
              <input type="checkbox" id="accept-terms" name="acceptedTerms" [(ngModel)]="acceptedTerms"> I accept the
              Terms
              and Conditions
            </label>
          </div>

          <!-- Step 7: Submit Button -->
          <div class="form-group">
            <button class="btn submit-tobc-btn" type="submit"
                    id="submit-button" (click)="form.submit()">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<div class="cta-container mt-5">
  <app-call-to-action></app-call-to-action>
</div>
