<div class="row">
  <div class="col-lg-7 d-flex justify-content-center flex-column mb-5">
    <div class="row section-3-header-section">
      <h3 class="section-3-tobc-header"> Benefits designed by<br/>people for people.</h3>
    </div>
    <div class="row section-3-paragraph-section">
      <p class="section-3-tobc-paragraph"> We embarked in this journey to innovate and create a <br/> framework of
        benefits that have
        a real appliance in our <br/> day to day activities, benefits which are aligned to the <br/> new way of working.
      </p>
    </div>

    <div class="row">
      <button class="btn contact-tobc-btn my-2 my-sm-0" type="submit" onclick="location.href = 'https://traveltodesk.com/contact';">Contact TOBC</button>
    </div>

  </div>
  <div class="col-lg-5 mb-5">
    <img src="assets/pictures/section_3_graphic.svg" alt="Section 3 Graphic" class="img-fluid"/>
  </div>
</div>
