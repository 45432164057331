<div class="section-7-container">
  <div class="row mt-5 d-flex justify-content-between flex-row-reverse">
    <div class="col-lg-4 d-flex justify-content-center flex-column mt-5">
      <div class="row section-7-header-section">
        <img class="img-fluid section-7-ttd-logo" src="assets/logos/logo_ttd_section_6.svg" alt="Graphic Image Travel To Desk Section 6"/>
      </div>

      <div class="row">
        <p class="section-7-tobc-paragraph">From Wonderland Locations to Sprint Locations, we have them all, audited and
          ready for your next corporate retreat or remote working experience.</p>
        <p class="section-7-tobc-paragraph">Create a unique working experience for your teams, find a creative method to
          commemorate a significant accomplishment or just spend quality time with your coworkers or family.
          Why? Because each and every moment of our lives is special.</p>

      </div>

      <div class="row section-7-btn">
        <button class="btn contact-tobc-btn my-2 my-sm-0" type="submit" onclick="location.href = 'https://traveltodesk.com/contact';">Contact TOBC</button>
      </div>

    </div>

    <div class="col-lg-7 section-7-image">
      <img class="img-fluid" src="assets/pictures/section_7_graphic.svg" alt="Graphic Image Travel To Desk Section 7"/>
    </div>
  </div>

</div>
