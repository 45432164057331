import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSplideModule } from 'ngx-splide';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import {CallToActionComponent} from './homepage/call-to-action/call-to-action.component';
import { CtaCardComponent } from './shared/cta-card/cta-card.component';
import { SectionThreeComponent } from './homepage/section-three/section-three.component';
import { SectionFourComponent } from './homepage/section-four/section-four.component';
import { SectionFiveComponent } from './homepage/section-five/section-five.component';
import { SectionSixComponent } from './homepage/section-six/section-six.component';
import { SectionSevenComponent } from './homepage/section-seven/section-seven.component';
import { SectionEightComponent } from './homepage/section-eight/section-eight.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    CallToActionComponent,
    CtaCardComponent,
    SectionThreeComponent,
    SectionFourComponent,
    SectionFiveComponent,
    SectionSixComponent,
    SectionSevenComponent,
    SectionEightComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSplideModule,
    FontAwesomeModule,
    NgxGoogleAnalyticsModule.forRoot('G-SM6YS1NQXQ'),
    NgxGoogleAnalyticsRouterModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
