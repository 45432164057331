import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cta-card',
  templateUrl: './cta-card.component.html',
  styleUrls: ['./cta-card.component.scss']
})
export class CtaCardComponent implements OnInit {

  @Input() text: string;
  @Input() title: string;
  @Input('btn-text') btnText: string;
  @Input() style: string;
  @Input() imgSrc: string;
  @Input() link: string;

  constructor() { }

  ngOnInit(): void {
  }

}
