<div class="card text-center cta-dimensions" [ngClass]="style === 'secondary' ? 'cta-card-body-bg-secondary' : ''">
  <div class="card-body d-flex align-items-center justify-content-center flex-column cta-card-body">
    <span class="cta-img">
      <img [src]="imgSrc" alt="logo_cta"/>
    </span>
    <h5 class="card-title cta-title" [ngClass]="style === 'secondary' ? 'cta-title-secondary' : ''">{{title}}</h5>
    <p class="card-text cta-text" [ngClass]="style === 'secondary' ? 'cta-text-secondary' : ''">{{text}}</p>
    <a [href]="link" class="btn p-3 cta-button-style" [ngClass]="style === 'secondary' ? 'cta-button-style-two' : ''">{{btnText}}</a>
  </div>
</div>
