<div class="row">
  <div class="col-lg-4">
    <app-cta-card
      title="Are you a Company?"
      text="Let’s start your unlimited benefits journey!"
      btn-text="Click Here"
      [imgSrc]="mouseOver ? 'assets/logos/section_2_company_logo_white.svg' : 'assets/logos/section_2_company_logo_blue.svg'"
      (mouseover)="mouseOver = true"
      (mouseout)="mouseOver = false"
      link="https://traveltodesk.com/blog-details/4"
      [style]="mouseOver ? SECONDARY : PRIMARY"></app-cta-card>
  </div>
  <div class="col-lg-4">
    <app-cta-card
      title="Are you a Digital Nomad?"
      text="Let’s start your unlimited benefits journey!"
      btn-text="Click Here"
      [imgSrc]="mouseOverTwo ? 'assets/logos/section_2_person_logo_white.svg' : 'assets/logos/section_2_person_logo_blue.svg'"
      (mouseover)="mouseOverTwo = true"
      (mouseout)="mouseOverTwo = false"
      link="https://traveltodesk.com/blog-details/6"
      [style]="mouseOverTwo ? SECONDARY : PRIMARY"></app-cta-card>
  </div>
  <div class="col-lg-4">
    <app-cta-card
      title="Are you an Employee?"
      text="Let’s start your unlimited benefits journey!"
      btn-text="Click Here"
      [imgSrc]="mouseOverThree ? 'assets/logos/section_2_briefcase_logo_white.svg' : 'assets/logos/section_2_briefcase_logo_blue.svg'"
      (mouseover)="mouseOverThree = true"
      (mouseout)="mouseOverThree = false"
      link="https://traveltodesk.com/blog-details/5"
      [style]="mouseOverThree ? SECONDARY : PRIMARY"></app-cta-card>
  </div>
</div>
